import LoadingSpinner from 'components/shared/LoadingSpinner';
import { DISTRICTS } from 'constants/districts';
import { ROLES, ROLES_BY_FULL_DISPLAY_NAME } from 'constants/roles';
import { TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX } from 'constants/themes/dimensions';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDistrictsState } from 'recoil/selectedDistrict/atom';
import {
  RollingAttritionListResultQueryResult,
  StaffingPlanListResultQueryResult,
  UpsertStaffingPlanMutationResult,
  useRollingAttritionListResultQuery,
  useStaffingPlanListResultQuery,
  useUpsertRollingAttritionMutation,
  useUpsertStaffingPlanMutation,
} from 'types/generated/graphql';
import { roleSort } from 'utils/roles';

import {
  Alert,
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

type StaffingPlanFromListQuery = Exclude<
  StaffingPlanListResultQueryResult['data'],
  undefined
>['staffingPlanListResult']['items'][number];
type StaffingPlanFromUpsert = Exclude<UpsertStaffingPlanMutationResult['data'], null | undefined>['upsertStaffingPlan'];
type VirtualStaffingPlan = Omit<StaffingPlanFromListQuery, 'id' | '__typename'> & { region: string };
type StaffingPlanForGrid = StaffingPlanFromListQuery | StaffingPlanFromUpsert | VirtualStaffingPlan;
type RollingWindow = Record<string, StaffingPlanForGrid>;

type RollingAttritionFromListQuery = Exclude<
  RollingAttritionListResultQueryResult['data'],
  undefined
>['rollingAttritionListResult']['items'][number];
type RollingAttritionFromUpsert = Exclude<
  UpsertStaffingPlanMutationResult['data'],
  null | undefined
>['upsertStaffingPlan'];
type VirtualRollingAttrition = Omit<RollingAttritionFromListQuery, 'id' | '__typename'> & { region: string };
type RollingAttritionForGrid = RollingAttritionFromListQuery | RollingAttritionFromUpsert | VirtualRollingAttrition;
type RollingAttritionWindow = Record<string, RollingAttritionForGrid>;

const ROW_HEIGHT_PX = 20;

const gridContainerStyle: SxProps<Theme> = {
  width: '100%',
  height: `calc(100vh - ${TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX}px)`,
  zIndex: 100,
};

const ExportToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

const ConfirmationDialog: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  year: number;
}> = ({ open, onClose, onSubmit, year }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Spread Total Over {year} Fiscal Year</DialogTitle>
    <DialogContent>
      <Typography gutterBottom>Are you sure you want to spread the total over the 12 month fiscal year?</Typography>
      <Typography>This will overwrite any existing staffing plans for {year}.</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onSubmit} color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
);

export const StaffingPlan: FC = () => {
  const { displayToast } = useToast();
  const selectedDistricts = useRecoilValue(selectedDistrictsState);
  const region = selectedDistricts && selectedDistricts.length === 1 ? selectedDistricts[0] : '';
  const regionName = DISTRICTS.find((district) => district.number === region)?.name ?? 'Unknown Region';
  const filteredRoles = ROLES.filter((role) => role.type !== 'CRAFT');
  const tooltipText = 'Total is spread across the 12 fiscal months of the year';
  const cumulativeFields = [
    'cumulativeFirstYear',
    'cumulativeSecondYear',
    'cumulativeThirdYear',
    'cumulativeFourthYear',
    'cumulativeFifthYear',
  ];
  const baseYear = DateTime.now().year;

  const [openDialog, setOpenDialog] = useState(false);
  const [staffingPlanData, setStaffingPlanData] = useState<{ newRow: any; oldRow: any } | null>(null);
  const [staffingPlanYear, setStaffingPlanYear] = useState(baseYear);

  const { data, loading } = useStaffingPlanListResultQuery({
    variables: {
      region: region,
    },
    skip: !region,
  });
  const staffingPlans = data?.staffingPlanListResult?.items ?? [];

  const { data: rollingAttritionData } = useRollingAttritionListResultQuery({
    variables: {
      region: region,
    },
    skip: !region,
  });

  const rollingAttrition = rollingAttritionData?.rollingAttritionListResult?.items ?? [];

  const [upsertStaffingPlan] = useUpsertStaffingPlanMutation({
    refetchQueries: ['StaffingPlanListResult'],
    awaitRefetchQueries: true,
  });

  const [upsertRollingAttrition] = useUpsertRollingAttritionMutation({
    refetchQueries: ['RollingAttritionListResult'],
    awaitRefetchQueries: true,
  });

  const startOfCurrentYear = DateTime.now().startOf('year');
  const rollingWindow: RollingWindow = {};
  for (let i = 0; i < 60; i++) {
    const month = startOfCurrentYear.plus({ months: i }).toISODate();
    if (month) {
      rollingWindow[month] = { region, monthOf: month, roleName: '', hiringTarget: 0 };
    } else {
      console.error(`Failed to determine month ${i} in rolling window.`);
    }
  }

  const rollingAttritionWindow: RollingAttritionWindow = {};
  for (let i = 0; i < filteredRoles.length; i++) {
    const roleName = filteredRoles[i].roleName;
    const attrition = rollingAttrition.find((rollingAttr) => rollingAttr.jobTitle === roleName)?.attrition ?? 0;
    if (roleName) {
      rollingAttritionWindow[roleName] = {
        region,
        jobTitle: roleName,
        customAttrition: '0',
        attrition: attrition.toString(),
      };
    } else {
      console.error(`Failed to determine role ${i} in rolling attrition window.`);
    }
  }

  const staffingPlansByRoleNameAndMonth = filteredRoles.reduce<Record<string, RollingWindow>>((accumulator, role) => {
    accumulator[role.fullDisplayName] = {
      ...Object.entries(rollingWindow).reduce<RollingWindow>((accumulator, [month, staffingPlan]) => {
        accumulator[month] = { ...staffingPlan, roleName: role.fullDisplayName };
        return accumulator;
      }, {}),
    };
    return accumulator;
  }, {});
  staffingPlans.forEach((staffingPlan) => {
    if (staffingPlan.roleName in staffingPlansByRoleNameAndMonth) {
      if (staffingPlan.monthOf in staffingPlansByRoleNameAndMonth[staffingPlan.roleName]) {
        staffingPlansByRoleNameAndMonth[staffingPlan.roleName][staffingPlan.monthOf] = staffingPlan;
      }
    } else {
      console.warn(`staffing plan rejected due to unexpected role "${staffingPlan.roleName}"`);
    }
  });

  const columns: GridColDef<any>[] = [
    { field: 'id' },
    {
      field: 'roleName',
      headerName: 'Role',
      width: 290,
      hideable: false,
      sortComparator: (a, b) => roleSort(ROLES_BY_FULL_DISPLAY_NAME[a], ROLES_BY_FULL_DISPLAY_NAME[b]),
    },
    {
      field: 'actualAttrition',
      headerName: 'Actual Attr.',
      hideable: false,
      type: 'string',
      valueGetter: (_, row) => {
        const secondIndex = 1;
        return (
          rollingAttrition.find((rollingAttr) => rollingAttr.jobTitle === row.roleName.split(' - ')[secondIndex])
            ?.attrition ?? '0'
        );
      },
      valueFormatter: (value) => `${(value * 100).toFixed(2)}%`,
    },
    {
      field: 'customAttrition',
      headerName: 'Custom Attr.',
      hideable: false,
      editable: true,
      type: 'string',
      valueGetter: (_, row) => {
        const secondIndex = 1;
        return (
          rollingAttrition.find((rollingAttr) => rollingAttr.jobTitle === row.roleName.split(' - ')[secondIndex])
            ?.customAttrition ?? '0'
        );
      },
      valueFormatter: (value) => {
        return `${Number(value).toFixed(2)}%`;
      },
      valueSetter: (value, row) => {
        return { ...row, customAttrition: value };
      },
    },
    {
      field: 'cumulativeFirstYear',
      headerName: 'Cumul. 1st Year',
      width: 130,
      hideable: false,
      editable: true,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(0, 13)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });
        return (
          <div key={params.id}>
            {
              <Tooltip title={tooltipText}>
                <span>{cumulativeValue}</span>
              </Tooltip>
            }
          </div>
        );
      },
    },
    {
      field: 'cumulativeSecondYear',
      headerName: 'Cumul. 2nd Year',
      width: 130,
      hideable: false,
      editable: true,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(13, 25)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return (
          <div key={params.id}>
            {
              <Tooltip title={tooltipText}>
                <span>{cumulativeValue}</span>
              </Tooltip>
            }
          </div>
        );
      },
    },
    {
      field: 'cumulativeThirdYear',
      headerName: 'Cumul. 3rd Year',
      width: 130,
      hideable: false,
      editable: true,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(25, 37)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return (
          <div key={params.id}>
            {
              <Tooltip title={tooltipText}>
                <span>{cumulativeValue}</span>
              </Tooltip>
            }
          </div>
        );
      },
    },
    {
      field: 'cumulativeFourthYear',
      headerName: 'Cumul. 4th Year',
      width: 130,
      hideable: false,
      editable: true,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(37, 49)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return (
          <div key={params.id}>
            {
              <Tooltip title={tooltipText}>
                <span>{cumulativeValue}</span>
              </Tooltip>
            }
          </div>
        );
      },
    },
    {
      field: 'cumulativeFifthYear',
      headerName: 'Cumul. 5th Year',
      width: 130,
      hideable: false,
      editable: true,
      align: 'right',
      renderCell: (params: any) => {
        let cumulativeValue = 0;
        Object.entries(params.row)
          .slice(49, 61)
          .forEach(([_key, value]) => {
            if (value && typeof value === 'object' && 'hiringTarget' in value) {
              cumulativeValue += (value as StaffingPlanForGrid).hiringTarget;
            }
          });

        return (
          <div key={params.id}>
            {
              <Tooltip title={tooltipText}>
                <span>{cumulativeValue}</span>
              </Tooltip>
            }
          </div>
        );
      },
    },
    ...Object.entries(rollingWindow).reduce<GridColDef<any>[]>((accumulator, [month]) => {
      const monthHeaderName = month.slice(0, -3);
      accumulator.push({
        field: month,
        headerName: `${monthHeaderName}`,
        type: 'number',
        editable: true,
        valueGetter: (_, row) => row[month]?.hiringTarget ?? 0,
        valueSetter: (value, row) =>
          Object.fromEntries(
            Object.entries(row).map(([columnKey, cellValue]) => {
              if (columnKey === month) {
                return [columnKey, { ...(cellValue as StaffingPlanForGrid), hiringTarget: value ?? 0 }];
              } else {
                return [columnKey, cellValue];
              }
            }),
          ),
      });
      return accumulator;
    }, []),
  ];

  const handleDialogSubmit = async () => {
    if (staffingPlanData) {
      // Create a deep copy of newRow to avoid modifying oldRow
      const newRow = JSON.parse(JSON.stringify(staffingPlanData.newRow));
      const { oldRow } = staffingPlanData;
      const editedCumulativeField = cumulativeFields.find((field) => newRow[field] !== oldRow[field]);

      if (editedCumulativeField) {
        const total = newRow[editedCumulativeField];
        const months = 12;
        // Value assigned to each month
        const baseValue = Math.floor(total / months);
        // Distribute the extra value over the months
        const remainder = total % months;

        // Determine the start date dynamically based on the existing month fields
        const monthFields = Object.keys(newRow)
          .filter((key) => key.match(/^\d{4}-\d{2}-\d{2}$/))
          .sort((a, b) => DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis());
        let startDateIndex;

        switch (editedCumulativeField) {
          case 'cumulativeFirstYear':
            startDateIndex = 0;
            break;
          case 'cumulativeSecondYear':
            startDateIndex = 12;
            break;
          case 'cumulativeThirdYear':
            startDateIndex = 24;
            break;
          case 'cumulativeFourthYear':
            startDateIndex = 36;
            break;
          case 'cumulativeFifthYear':
            startDateIndex = 48;
            break;
          default:
            startDateIndex = null;
        }

        if (startDateIndex !== null && monthFields[startDateIndex]) {
          const startDate = DateTime.fromISO(monthFields[startDateIndex]);
          for (let i = 0; i < months; i++) {
            const monthField = startDate.plus({ months: i }).toISODate();
            if (monthField && newRow[monthField]) {
              newRow[monthField].hiringTarget = baseValue + (i < remainder ? 1 : 0);
            }
          }
        }
      }

      setStaffingPlanData(null);
      await onProcessRowUpdate(newRow, oldRow);
      setOpenDialog(false);
    }
  };

  const handleDialogClose = () => {
    setStaffingPlanData(null);
    setOpenDialog(false);
  };

  const onProcessRowUpdate = async (newRow: any, oldRow: any) => {
    const editedCumulativeField = cumulativeFields.find((field) => newRow[field] !== oldRow[field]);

    if (editedCumulativeField) {
      let yearOffset = 0;
      switch (editedCumulativeField) {
        case 'cumulativeFirstYear':
          yearOffset = 0;
          break;
        case 'cumulativeSecondYear':
          yearOffset = 1;
          break;
        case 'cumulativeThirdYear':
          yearOffset = 2;
          break;
        case 'cumulativeFourthYear':
          yearOffset = 3;
          break;
        case 'cumulativeFifthYear':
          yearOffset = 4;
          break;
        default:
          yearOffset = 0;
      }

      const affectedYear = baseYear + yearOffset;
      setStaffingPlanYear(affectedYear);
      setStaffingPlanData({ newRow, oldRow });
      setOpenDialog(true);
    }

    Object.entries<StaffingPlanForGrid | string>(newRow).forEach(([columnKey, newCellValue]) => {
      if (oldRow[columnKey]?.hiringTarget !== undefined && newCellValue !== undefined) {
        if (typeof newCellValue !== 'string' && oldRow[columnKey]?.hiringTarget !== newCellValue.hiringTarget) {
          return upsertStaffingPlan({
            variables: {
              input:
                'id' in newCellValue
                  ? { id: newCellValue.id, hiringTarget: newCellValue.hiringTarget }
                  : {
                      region,
                      roleName: newCellValue.roleName,
                      monthOf: newCellValue.monthOf,
                      hiringTarget: newCellValue.hiringTarget,
                    },
            },
          }).catch((error) => {
            displayToast(
              'Error: Something went wrong while trying to update the staffing plan. Please try again. If the problem persists, please contact support.',
              'error',
            );
            console.error(error);
          });
        }
      }
    });

    if (oldRow?.customAttrition !== newRow.customAttrition || !('customAttrition' in oldRow)) {
      const secondIndex = 1;
      const roleName = newRow.roleName?.split(' - ')[secondIndex] ?? oldRow?.rowName?.split(' - ')[secondIndex];
      return upsertRollingAttrition({
        variables: {
          input: {
            region,
            regionRoleKey: region + '_' + roleName,
            jobTitle: roleName,
            customAttrition: newRow.customAttrition,
          },
        },
      }).catch((error: any) => {
        displayToast(
          'Error: Something went wrong while trying to update the staffing plan. Please try again. If the problem persists, please contact support.',
          'error',
        );
        console.error(error);
      });
    }
    return newRow;
  };

  const onProcessRowUpdateError = (error: any) => {
    displayToast(
      'Error: Something went wrong while trying to update the staffing plan. Please try again. If the problem persists, please contact support.',
      'error',
    );
    console.error(error);
  };

  return (
    <Box sx={gridContainerStyle}>
      {!region ? (
        <Alert severity="warning">Please select exactly one region above to load that region's staffing plan.</Alert>
      ) : loading ? (
        <LoadingSpinner />
      ) : !data ? (
        <Alert severity="error">Failed to access staffing plan. If the problem persists, please contact support.</Alert>
      ) : (
        <Stack spacing={1} sx={{ height: '100%' }}>
          <Typography>Staffing Plan for {regionName}</Typography>
          <DataGrid
            rows={Object.entries(staffingPlansByRoleNameAndMonth).map(([roleName, staffingPlansInRollingWindow]) => ({
              roleName,
              ...staffingPlansInRollingWindow,
            }))}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            slots={{ toolbar: ExportToolbar }}
            columns={columns}
            getRowId={(row) => row.roleName + row.id}
            pageSizeOptions={[filteredRoles.length]}
            rowHeight={ROW_HEIGHT_PX}
            columnHeaderHeight={ROW_HEIGHT_PX}
            autoHeight={false}
            hideFooter={true}
            showCellVerticalBorder
            disableMultipleRowSelection
            processRowUpdate={onProcessRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
          />
        </Stack>
      )}
      <ConfirmationDialog
        open={openDialog}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        year={staffingPlanYear}
      />
    </Box>
  );
};

export default StaffingPlan;
